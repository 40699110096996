import { MsalGuardConfiguration, MsalInterceptorConfiguration } from '@azure/msal-angular';
import { ProtectedResourceScopes } from '@azure/msal-angular/msal.interceptor.config';
import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication,
  PublicClientApplication,
} from '@azure/msal-browser';

import { inject } from '@angular/core';

import { APP_CONFIG } from '../services/app-config.token';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function MSALInstanceFactory(): IPublicClientApplication {
  const config = inject(APP_CONFIG);
  return new PublicClientApplication({
    auth: {
      clientId: config.ClientId,
      authority: `https://login.microsoftonline.com/${config.Tenant}`,
      redirectUri: config.AdminAppUrl,
      postLogoutRedirectUri: null,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE,
    },
    system: {
      allowRedirectInIframe: true,
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const config = inject(APP_CONFIG);
  const protectedResourceMap = new Map<string, Array<string | ProtectedResourceScopes> | null>();

  config.MSALScopes.forEach(({ Url, Permissions }) => {
    protectedResourceMap.set(Url, Permissions);
  });

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
  };
}
