import { map, Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

@Injectable()
export class IconsService {
  private readonly http = inject(HttpClient);
  private spriteUrl = 'assets/sprite.svg';

  getIconNames(): Observable<string[]> {
    return this.http.get(this.spriteUrl, { responseType: 'text' }).pipe(
      map(responseText => {
        const parser = new DOMParser();
        const svgDocument = parser.parseFromString(responseText, 'image/svg+xml');
        const symbols = svgDocument.querySelectorAll('symbol');

        return Array.from(symbols).map(symbol => symbol.getAttribute('id'));
      }),
    );
  }
}
