import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, OnChanges } from '@angular/core';

import { SimpleChanges } from '../../models/simple-changes.model';

@Component({
  selector: 'app-icon',
  standalone: true,
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent implements OnChanges {
  @Input() icon: string;
  @Input() color: string;
  @Input() ariaLabel: string;
  @Input() size: number;

  @HostBinding('class.color-inherited')
  @Input()
  colorInherit = false;

  constructor(private elementRef: ElementRef) {}

  private readonly defaultIconText = 'Please provide name for this icon';

  public get element(): HTMLElement {
    return this.elementRef.nativeElement;
  }

  ngOnChanges(changes: SimpleChanges<this>): void {
    if (changes.icon && changes.icon.currentValue) {
      this.handleIconChanges(changes.icon.currentValue as string);
    }
    if (changes.color?.currentValue) {
      this.element.style.color = this.color;
    }
    if (changes.size?.currentValue) {
      this.element.style.width = `${this.size}px`;
      this.element.style.height = `${this.size}px`;
    }
  }

  private handleIconChanges(iconName: string): void {
    if (iconName === this.defaultIconText || !iconName) {
      this.icon = 'image-lib';
    }
  }
}
